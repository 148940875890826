import {  Popup } from "devextreme-react";
import { CustomerModel } from "../../../types/crm-contact";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerInfoUpdate } from "./infoUpdate";
import { CustomerUserList } from "./usersList";
import { B2BCustomerDetails } from "../../../pages";

export const B2BPopup = (
    { mainRefresh, rowData , isPanelOpened,changePanelOpened,isNewRecord,openNewRow } :
    { mainRefresh: () => void, rowData:CustomerModel, isPanelOpened,changePanelOpened,isNewRecord,openNewRow }
  ) =>{
    const [readOnly, setReadOnly] = useState(true);

    useEffect(()=>{
        if(isPanelOpened){
            setExpandTable(false)
            if(isNewRecord){
                setReadOnly(false)
            }else{
                setReadOnly(true)
            }
        }
    },[isPanelOpened]);

    const readOnlyTrue = useCallback(() => {
        setReadOnly(true);
    }, [readOnly]);

    const readOnlyFalse = useCallback(() => {
        setReadOnly(false);
    }, [readOnly]);

    const {t} = useTranslation();

    const[expandTable, setExpandTable] = useState(false);

    return (
        <Popup
                    key={rowData?.customerRef}
                    visible={isPanelOpened}
                    hideOnOutsideClick={true}
                    title={rowData?.customerName}
                    showCloseButton={true}
                    width={"90%"}
                    height={"90%"}
                    onHiding={changePanelOpened}
                    contentRender={() => (
                //         <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                //     {!expandTable&&(
                //         <div style={{position: 'sticky', top: 0, zIndex: 1, borderBottom: '1px solid #ccc',paddingBottom: '10px'}}>
                //         <CustomerInfoUpdate openNewRow={openNewRow} isPanelOpened={isPanelOpened} changePanelOpened={changePanelOpened} isNewRecord={isNewRecord} readOnly={readOnly} readOnlyTrue={readOnlyTrue} readOnlyFalse={readOnlyFalse} mainRefresh={mainRefresh} customer={rowData} ></CustomerInfoUpdate>
                //         </div>
                //     )}
                //     <div style={{ flex: "auto", overflowY: 'auto' }}>
                //         <CustomerUserList customerRef={rowData?.customerRef}></CustomerUserList>
                //   {
                //     /*
                //     <CustomerLinesList expandTable={expandTable} setExpandTable={setExpandTable} customer={rowData} readOnly={readOnly}></CustomerLinesList>

                //     */
                //   }
                //     </div>
                // </div>

                <B2BCustomerDetails rowData={rowData}></B2BCustomerDetails>

                    )}
                        />);

}