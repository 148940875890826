
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckBox } from 'devextreme-react/check-box';
import classNames from 'classnames';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import Form, { Item as FormItem, GroupItem, ColCountByScreen, Item, Label } from 'devextreme-react/form';
import { formatNumber } from 'devextreme/localization';
import {  CustomerUserModel } from '../../../types/crm-contact';
import { FormTextbox, FormPhoto, ContactStatus } from '../..';
import { useScreenSize } from '../../../utils/media-query';
import ValidationGroup from 'devextreme-react/validation-group';
import { customerUpdateRequest, newCustomerUserRequest } from '../../../api/requests';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { TextBox } from 'devextreme-react';
import { Eye, EyeOff } from 'react-feather';

const GroupTemplate = (item) => <div>Assigned: {item.key}</div>;

const renderCustomTitle = (item) => {
  return (
    <>
      <span>{item.title}</span>
    </>
  );
};


export const CustomerUserPanelDetails = (

  {customerUserRefresh, contact, isOpened, changePanelOpened, onDataChanged, changePanelPinned } :
  {customerUserRefresh:() => void, contact: CustomerUserModel, isOpened: boolean, changePanelOpened:(value: boolean)=> void, onDataChanged:(data)=> void, changePanelPinned:() => void }) => {

    const {t} = useTranslation();
    const [isPinned, setIsPinned] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const { isLarge, isMedium } = useScreenSize();

  const passwordEditorOptions = { stylingMode: 'filled', placeholder:  t('password'), mode: 'password' };

  const [userActive, setUserActive] = useState(contact.userActive == 1 ? true : false);

  function setStatus(val){
    setUserActive(val);
    if(val){
      contact.userActive = 1;
    }else{
      contact.userActive = 0;
    }
  }

  const updateField = (field: string) => (value) => {
    contact[field] = value;
    onDataChanged({ ...contact, ...{ [field]: value } });
  };

  useEffect(() => {

    setUserActive(contact.userActive == 1 ? true : false);
    changePanelPinned();
  }, [isPinned,contact.userActive]);

  const onPinClick = useCallback(() => {
    setIsPinned(!isPinned);
  }, [isPinned]);

  const onClosePanelClick = useCallback(() => {
    setIsPinned(false);
    changePanelOpened(false);
  }, []);

  const toggleEditHandler = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  const onSaveClick = useCallback(({ validationGroup } : ButtonTypes.ClickEvent) => {
  //   if (!validationGroup?.validate().isValid) return;
     contact.userActive = userActive ? 1 : 0;
     updateCustomer();
     changePanelOpened(false);
  }, [userActive, JSON.stringify(contact)]);


  var updateCustomer = async () => {
    try {
    await newCustomerUserRequest(contact).then(function(res){
        if(res.resCode == 200){
          customerUserRefresh();
          notify(t("updateSuccessfull"), "success", 1500);
        }else{
          notify(res.resMessage,"error",3000);
        }

      });
    } catch (error) {
      console.error('API hatası:', error);
    }
  };

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <div id='contact-panel' className={classNames({ 'panel': true, 'open': isOpened, 'pin': isPinned && (isLarge || isMedium) })}>
      <div className='' style={{ margin:"15px"}}>
        <Toolbar className='panel-toolbar'>
          <ToolbarItem location='before'>
            <span className='contact-name value'>{contact.userName}</span>
          </ToolbarItem>

          <ToolbarItem
            location='after'
            visible={isLarge || isMedium}
          >
            <Button
              icon={isPinned ? 'pin' : 'unpin'}
              stylingMode='text'
              onClick={onPinClick}
            />
          </ToolbarItem>
          <ToolbarItem location='after'>
            <Button
              icon='close'
              stylingMode='text'
              onClick={onClosePanelClick}
            />
          </ToolbarItem>
        </Toolbar>
        <ScrollView className='panel-scroll'>

          <ValidationGroup>
            <div className='data-part border'>
              <Form
                className={classNames({ 'plain-styled-form': true, 'view-mode': !isEditing })}
              >
                <ToolbarItem location='before'>
            <CheckBox
              value={userActive}
              text={t("active")}
              onValueChanged={(e) => setStatus(e.value)}
            />
          </ToolbarItem>

                <GroupItem colCount={2} cssClass='photo-row'>
                  <ColCountByScreen xs={2} />

                  <GroupItem>

                  </GroupItem>
                </GroupItem>

                <GroupItem cssClass='contact-fields-group'>

                    <FormItem cssClass='accent'>
                      <FormTextbox
                        label={t("userName")}
                        value={contact.userName}
                        isEditing={!isEditing}
                        icon='user'
                        onValueChange={updateField('userName')}
                      />
                    </FormItem>

                    <FormItem cssClass='accent'>
                      <FormTextbox
                        label={t("email")}
                        value={contact.userEmail}
                        isEditing={!isEditing}
                        icon='email'
                        onValueChange={updateField('userEmail')}
                      />
                    </FormItem>

                    <FormItem   editorOptions={passwordEditorOptions} cssClass='accent'>
                    <div style={{ position: 'relative' }}>
                      <TextBox
                        label={t("password")}
                        value={contact.userPassword}
                        mode={isPasswordVisible ? 'text' : 'password'}
                        onValueChange={updateField('userPassword')}
                      />
                       <div
                        onClick={togglePasswordVisibility}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer'
                        }}
                      >
                        {isPasswordVisible ? <EyeOff /> : <Eye />}
                      </div>
                    </div>
                    </FormItem>

                    <FormItem cssClass='accent'>
                      <FormTextbox
                        label={t("comment")}
                        value={contact.comment}
                        isEditing={!isEditing}
                        icon='comment'
                        onValueChange={updateField('comment')}
                      />
                    </FormItem>

                </GroupItem>
              </Form>
            </div>

            <div className='data-part data-part-toolbar border'>
              <Toolbar>

                <ToolbarItem location='after' visible={true}>
                  <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
                </ToolbarItem>

              </Toolbar>
            </div>
          </ValidationGroup>

          <div className="list-container">

  </div>
        </ScrollView>
      </div>
    </div>
  );
};
