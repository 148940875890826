import notify from "devextreme/ui/notify";
import { getCustomerGroupsRequest } from "./requests";
import { CustomerModel } from "../types/crm-contact";
import { appInfo } from "../app-info";

export function setAppToken(token:string){
  sessionStorage.setItem("appToken",token)
}

export const getAppToken = ()=>{
  return sessionStorage.getItem("appToken");
}


export function setVendorId(vendorId:string){
  sessionStorage.setItem("vendorId",vendorId)
}

export const getVendorId = ()=>{
  return sessionStorage.getItem("vendorId");
}


export function setUserName(userName:string){
  sessionStorage.setItem("userName",userName)
}

export const getUserName = ()=>{
  return sessionStorage.getItem("userName")??"";
}

export function setFirmName(firmName:string){
  sessionStorage.setItem("firmName",firmName)
}

export const getFirmName = ()=>{
  return sessionStorage.getItem("firmName")??"";
}

/*
export const getCustomerGroups = async (token) => {
  if(appInfo().company=="akar"){

  }else{
    const response = getCustomerGroupsRequest(token).then(function(res){
      if(res.data.resCode == 200){
          sessionStorage.setItem('customerGroups', JSON.stringify(res.data.dataSet));
      }else{
          notify(res.data.resMessage,"error",3000)
      }
    });
  }

}
*/

export const customersToSession = (customers) => {
  sessionStorage.setItem("customers",customers);
}

export const customersFromSession = ()=>{
  return sessionStorage.getItem("customers")??"";
}


export const customerListFilterToSession = (select:string) => {
  sessionStorage.setItem("customerFilter",select);
}

export const customerListFilterFromSession = ()=>{
  return sessionStorage.getItem("customerFilter")??1;
}


export const customerListSelectToSession = (select:string) => {
  sessionStorage.setItem("customerSelect",select);
}

export const customerListSelectFromSession = ()=>{
  return sessionStorage.getItem("customerSelect")??"";
}


export const campaignListStatusToSession = (select:string) => {
  sessionStorage.setItem("campaignListStatus",select);
}

export const campaignListStatusFromSession = ()=>{
  return sessionStorage.getItem("campaignListStatus")??1;
}


export const salesmanListStatusToSession = (select:string) => {
  sessionStorage.setItem("salesmanListStatus",select);
}

export const salesmanListStatusFromSession = ()=>{
  return sessionStorage.getItem("salesmanListStatus")??1;
}

export const routesListStatusToSession = (select:string) => {
  sessionStorage.setItem("routesListStatus",select);
}

export const routesListStatusFromSession = ()=>{
  return sessionStorage.getItem("routesListStatus")??1;
}

export const segmentsListStatusToSession = (select:string) => {
  sessionStorage.setItem("segmentsListStatus",select);
}

export const segmentsListStatusFromSession = ()=>{
  return sessionStorage.getItem("segmentsListStatus")??1;
}

export const imageStatusToSession = (select:string) => {
  sessionStorage.setItem("imageListStatus",select);
}

export const imageStatusFromSession = ()=>{
  return sessionStorage.getItem("imageListStatus")??0;
}




 const customersUpdateSess = (code:number,customerM:CustomerModel)=>{
  // Önce session'dan mevcut listeyi alın
const existingListString = sessionStorage.getItem("customers")??"[]";

// JSON formatındaki string'i JavaScript nesnesine dönüştürün
const existingList = JSON.parse(existingListString);

// Güncellenecek öğenin customerCode değeri
const targetcustomerCode = code;

// Güncellenecek öğenin index'ini bulun
const targetIndex = existingList.findIndex(customer => customer.customerCode == targetcustomerCode);

if (targetIndex !== -1) {
    // Öğe bulunduysa güncellemeyi yapın
    existingList[targetIndex]= customerM;

    // Güncellenmiş listeyi JSON formatına dönüştürün
    const updatedListString = JSON.stringify(existingList);

    // Session'da güncellenmiş listeyi saklayın
    sessionStorage.setItem("customers", updatedListString);

    console.log("Liste başarıyla güncellendi!");
} else {
    console.log("Belirtilen customerCode değerine sahip öğe bulunamadı.");
}

}


