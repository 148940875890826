import React, { useState,useCallback } from 'react';
import { Popup,LoadPanel } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { confirm } from 'devextreme/ui/dialog';
import { useTranslation } from 'react-i18next';

import uploadImage from "../../../assets/upload-image.png";
import { appInfo } from '../../../app-info';
const ImageEditPopup = ({ rowData, isPanelOpened, changePanelOpened }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const API_BASE_URL = appInfo().imageServerURL;
  // const API_BASE_URL = 'http://localhost:3002';
  const [renamedFile, setRenamedFile] = useState<File | null>(null);
  const [loading,setLoading] = useState(false)
  const [imageSrc, setImageSrc] = useState(null);
  const [key, setKey] = useState(0);
  const {t} = useTranslation();

   // Pop-up'ı yeniden render etmek için
  if (!rowData || !rowData.itemCode) {
    return <div></div>;
  }

  // Dosya seçildiğinde çalışacak fonksiyon
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newFileName = `${rowData.itemCode}.${file.name.split('.').pop()}`;
      const renamedFile = new File([file], newFileName, { type: file.type });
      setSelectedFile(renamedFile);

      // Dosya önizlemesini oluştur
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDownload = async (itemCode) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/download`, {
        itemCode
      }, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${itemCode}.jpg`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      notify('File downloaded successfully', 'success');
    } catch (error) {
      console.error('File download error:', error);
      notify('File download error: ' + error, 'error');
    }
  };
  const handleUpload = async (file: File) => {
    setLoading(true);
    if (!file) {
      notify('Please select a file!', 'warning');
      return;
    }
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await fetch(`${API_BASE_URL}/upload`, {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        notify('File uploaded successfully', 'success');
        setKey(key=>key + 1);
      } else {
        notify('Upload error occurred', 'error');
      }
    } catch (error) {
      console.error('Error:', error);
      notify('Upload error occurred: ' + error, 'error');
    }finally{
      setLoading(false)
    }
  };
  // Butonla dosya seçme
  const handleImageButtonClick = (itemCode: string) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.style.display = 'none';
    document.body.appendChild(input);
    input.click();
    input.addEventListener('change', async (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        const newFileName = `${itemCode}.${file.name.split('.').pop()}`;
        const newRenamedFile = new File([file], newFileName, { type: file.type });
        setRenamedFile(newRenamedFile);
        console.log('newRenamedFile= ' + newFileName);

        // Yükleme işlemi sadece dosya seçildikten sonra yapılır
        await handleUpload(newRenamedFile);
        setRenamedFile(null);  // Yükleme tamamlandıktan sonra state sıfırlanır
      }
      document.body.removeChild(input);
    });
  };
  const handleDrop = async (event, imageType) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      const newFileName = `${rowData.itemCode}.${file.name.split('.').pop()}`;
      const renamedFile = new File([file], newFileName, { type: file.type });
      await handleUpload(renamedFile);
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
 // Silme
 const handleDelete = async (itemCode: string) => {
  const result = await confirm(t("areYouSureDeletingImage"), t("deleteProcess"));
  if (result) {
  try {
    const response = await axios.post(`${API_BASE_URL}/delete`, { itemCode });
    console.log(response.data);
    notify(t("successfull"), 'success');
    setKey(key=>key + 1);
  } catch (error) {
    console.error('File delete error:', error);
    notify('File delete error: ' + error, 'error');
  }
  } else {
  }
}

const refreshImage = (imgSrc) => {
  const timestamp = new Date().getTime();
  return `${imgSrc}?timestamp=${timestamp}`;
};

  return (
    <div key={key}>
    <Popup
      visible={isPanelOpened}
      hideOnOutsideClick={true}
      title={t("imageEdit")}
      maxWidth={"800px"}
      minWidth={"500px"}
      height={"auto"}
      showCloseButton={true}
      onHiding={changePanelOpened}
    >
    <LoadPanel showIndicator shading visible={loading} hideOnOutsideClick  />

      <div style={{ display: 'flex', gap: '10px', marginLeft:'10px', }}>
              <Button
                text=''
                icon='upload'
                onClick={() => handleImageButtonClick(rowData.itemCode)}
                stylingMode='contained'
                // type="default"
              />
                <Button
                  text=""
                  icon="download"
                  stylingMode="contained"
                  // type="default"
                  onClick={() =>handleDownload(rowData.itemCode)}
                />
                <Button
                  text=""
                  icon="trash"
                  stylingMode="contained"
                  // type="danger"
                  onClick={()=>handleDelete(rowData.itemCode)}
                />
                <Button icon='refresh' text='' stylingMode='contained' onClick={()=>[]} />
            </div>



      <div style={{ paddingTop: '20px',paddingBottom:"20px",paddingLeft:"10px", paddingRight:"10px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '10px', width: '100%', height: "275px", display: 'flex', justifyContent: 'space-between' }}>

          <div
            style={{ flex: 1, marginRight: '10px', border: '2px dashed gray', padding: '10px', textAlign: 'center' }}
            onDrop={(event) => handleDrop(event, 'catalog')}
            onDragOver={handleDragOver}
          >
            <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>{t("catalogImage")}</div>
            <img
              src={refreshImage(`${appInfo().imageURL}/catalog/${rowData.itemCode}.jpg`)}
              alt="Catalog"
              style={{ width: 'auto', height: '200px', objectFit: 'contain' }}
              onError={(e) => {
                const imgElement = e.target as HTMLImageElement;
                imgElement.src = uploadImage;
              }}
            />
          </div>

          <div
            style={{ flex: 1, marginRight: '10px', border: '2px dashed gray', padding: '10px', textAlign: 'center' }}
            onDrop={(event) => handleDrop(event, 'catalog')}
            onDragOver={handleDragOver}
          >
            <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>{t("thumbsImage")}</div>

            <img
              src={refreshImage(`${appInfo().imageURL}/thumbs/TH${rowData.itemCode}.jpg`)}
              alt="Thumbs"
              style={{ width: 'auto', height: '200px', objectFit: 'contain' }}
              onError={(e) => {
                const imgElement = e.target as HTMLImageElement;
                imgElement.src = uploadImage;
              }}
            />
          </div>


        </div>

      </div>
      <div style={{ flex: 1, marginLeft: '10px' }}>
            <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>{t("productInfo")}</div>
            <div><strong>{t("productCode")}:</strong> {rowData.itemCode}</div>
            <div><strong>{t("productName")}:</strong> {rowData.itemName}</div>
          </div>

    </Popup>
    </div>
  );
};
export default ImageEditPopup;
