import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Popup } from 'devextreme-react/popup';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Column, Toolbar, Item, LoadPanel,
  Export,
  DataGridTypes,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef,

} from 'devextreme-react/data-grid';

import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';

import { campaignProductSearchRequest, generalIncentivesInsertRequest, generalProductSearch } from '../../../api/requests';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { appInfo } from '../../../app-info';

const IncentiveProductSearch = ({ visible, onHiding, getList }) => {
  const [products, setProducts] = useState([]);
  const {t} = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState([]); // Seçili ürünlerin yönetimi için
  const gridRef = useRef<DataGridRef>(null);
  const [totalCount, setTotalCount]= useState(0);

  function getListFromApi(){
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    generalProductSearch(0,1).then(data => {
      if(data.resCode==200){
        setProducts(data.dataSet);
        setTotalCount(data.dataSet.length);
      }else{
        notify(data.resMessage,"error")
      }
  }).finally(()=>{
    gridRef.current?.instance().endCustomLoading()
  });
  }

  useEffect(() => {
    if (visible) {
      getListFromApi();
    }
    clearSelections();
  }, [visible]);


    const onAddButtonClick = () => {
        gridRef.current?.instance().beginCustomLoading(t("loading"));
        var i =0;
        selectedProducts.forEach((product, index) => {

            generalIncentivesInsertRequest(product["erpItemRef"]).then((response)=>{
                if(response["resCode"]==200){
                    notify(product["productName"]+" eklendi","success");
                }else{
                    notify(response["resMessage"],"error");
                }
                i++;
                if(i==selectedProducts.length){
                    gridRef.current?.instance().endCustomLoading();
                    onHiding();
                    getList();
                    clearSelections();
                }
            });
        });
    };

    const clearSelections = () => {
        setSelectedProducts([])
        gridRef.current?.instance().clearFilter();
        gridRef.current?.instance().clearSelection();
    };


    const onSelectionChanged = (e) => {
        setSelectedProducts(e.selectedRowsData); // Seçili satırları güncelle
    };

    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        if (e.format === 'pdf') {
          const doc = new JsPdf();
          exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
          }).then(() => {
            doc.save('Products.pdf');
          });
        } else {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Page1');

          exportDataGridToXLSX({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Products.xlsx');
            });
          });
          e.cancel = true;
        }
      };

    return (
        <Popup
          visible={visible}
          onHiding={onHiding}
          title={t("products")}
          width={"85%"}
          height={"85%"}
          dragEnabled={true}
          showCloseButton={true}

        >

        <DataGrid
          ref={gridRef}
          dataSource={products}
          keyExpr="productCode"
          onSelectionChanged={onSelectionChanged}
          showBorders={true}
          onExporting={onExporting}
          noDataText={t("noData")}
          columnAutoWidth={false}
          allowColumnReordering
          height={"100%"}
          >


          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <LoadPanel showPane={false}  />
          <SearchPanel placeholder={t("search")} searchVisibleColumnsOnly={false} width={"300px"} visible={true} />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Selection  mode='multiple' />
          <HeaderFilter visible />
          <Scrolling mode='virtual' />
          <LoadPanel showPane={true}  />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>

          <Item name='searchPanel' location='before'  />


          <Item location='before' locateInMenu='auto'>
            <Button
                  icon='clearformat'
                  hint={t("clearFilters")}
                  stylingMode='text'
                  onClick={clearSelections}
                />
          </Item>


            <Item location='before' locateInMenu='auto'>
                <Button
                        icon='refresh'
                        stylingMode='text'
                        hint={t("refreshList")}
                        onClick={getListFromApi}
                    />
                </Item>

          <Item location='before' name='columnChooserButton' locateInMenu='auto' />

          <Item name='exportButton' location='before' />

          <Item location='center' locateInMenu='auto'>
          <p>{t("selected")}: {selectedProducts.length} / {totalCount}</p>
          </Item>

          <Item location='after' locateInMenu='auto'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                icon='check'
                text={t("select")}
                type='default'
                stylingMode='contained'
                visible={true}
                onClick={onAddButtonClick}
                disabled={!(selectedProducts.length>0)}
              />
                    <div style={{ marginLeft: '10px' }}>
                      <Button
                      text={t("close")}
                      icon='close'
                      stylingMode='contained'
                      style={{backgroundColor:"gray"}}
                      type='default'
                      onClick={onHiding}
                    />
                    </div>
                  </div>
          </Item>



          </Toolbar>

              <Column hidingPriority={19} dataField="productCode" width={120} caption={t("productCode")} />
              <Column hidingPriority={18} dataField="productName"  caption={t("productName")} />
              <Column hidingPriority={17} dataField="productGroup" width={200} caption={t("group")}/>
              <Column hidingPriority={16} dataField="productBrand" width={150} caption={t("brand")} />
              <Column hidingPriority={15} dataField="speCode" width={130} caption={t("specCode1")} visible={true} />
              <Column hidingPriority={14} dataField="speCode2" width={130} caption={t("specCode2")} visible={true}/>
              <Column hidingPriority={13} dataField="speCode3" width={130} caption={t("specCode3")} visible={true} />
              <Column hidingPriority={12} dataField="speCode4" width={130} caption={t("specCode4")} visible={true} />
              <Column hidingPriority={11} dataField="speCode5" width={130} caption={t("specCode5")} visible={true} />
              <Column hidingPriority={10} dataField="producerCode" caption={t("producerCode")} visible={false} />

        </DataGrid>
        </Popup>
      );
};

export default IncentiveProductSearch;
