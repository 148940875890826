import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import From, { Item as ItemForm, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import { ContactStatus } from '../../utils/contact-status/ContactStatus';
import { FormTextbox } from '../../utils/form-textbox/FormTextbox';
import { CustomerModel } from '../../../types/crm-contact';
import { Button, CheckBox } from 'devextreme-react';
import { customerUpdateRequest } from '../../../api/requests';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { useCustomerContext } from '../../../contexts/customers';
import { useNavigate } from 'react-router-dom';


const statusRender = (text: string) => (
  <div className='status-editor-field'>
    <ContactStatus text={text} showText={false} />
    <TextBox
      className={`status-contact status-${"text".toLowerCase()}`}
      inputAttr={{ statusEditorInput: '' }}
      readOnly
      text={text}
      hoverStateEnabled={false}
    />
  </div>
);

const statusItemRender = (text: string) => <ContactStatus text={text} />;

export const ContactFromDetails = ({ data, editing, updateField }: {
  data: CustomerModel, editing: boolean, updateField: (field: string | number) => (value: string | number) => void
}) => {
  const {t} = useTranslation();
  const [cGroups, setCGroups] = useState([]);
  const [customerGroupRef, setCustomerGroupRef] = useState(data.customerGroupRef);
  const [status, setStatus] = useState(data.customerActive==1?true:false);
  const stylingMode = 'filled';
  const { customers, setCustomers } = useCustomerContext();

  useEffect(()=>{
    const storedData = sessionStorage.getItem('customerGroups');
    if (storedData) {
      setCGroups(JSON.parse(storedData));
    }
    setCustomerGroupRef(data.customerGroupRef);
  },[data.customerGroupRef])


  const handleOnChangeCG = (event) => {
    setCustomerGroupRef(event.value);
  };


  var updateCustomer = async () => {

    try {
    await customerUpdateRequest(
      data.customerRef,
      status?1:0,
      data.customerCode,
      data.customerName,
      customerGroupRef,
      data.erpCustomerRef,
      data.erpShippingAdrRef,
      data.productGroupRef
      ).then(function(res){

      });
    } catch (error) {
      console.error('API hatası:', error);
    }

  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate('/b2b-customer-list');
  };

  return (
    <From
      className={classNames({ 'plain-styled-form': true, 'view-mode': !editing })}
      labelMode='floating'
    >
      <GroupItem >
      <ItemForm>
        <div style={{display:"flex",justifyContent:"flex-end"}}>
        <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={updateCustomer} />
        </div>
          </ItemForm>
        <ColCountByScreen xs={1} />


        <GroupItem>
          <ItemForm>
          <CheckBox
              value={status}
              text={t("active")}
              onValueChanged={(e) => setStatus(e.value)}
            />
          </ItemForm>

          <ItemForm>
            <FormTextbox
              label={t("customerCode")}
              value={data.customerCode}
              isEditing={!editing}
              onValueChange={updateField('customerCode')}
            />
          </ItemForm>

          <ItemForm>
            <FormTextbox
              label={t("customerName")}
              value={data.customerName}
              isEditing={!editing}
              onValueChange={updateField('customerName')}
            />
          </ItemForm>

          <ItemForm >
                      <p>{t("customerGroup")}</p>
                      <SelectBox
                       id="customerGroups"
                       value={customerGroupRef==0?cGroups[0]["customerGroupRef"]:customerGroupRef}
                       dataSource={cGroups}
                       displayExpr="customerGroupName"
                       valueExpr="customerGroupRef"
                       onValueChanged={handleOnChangeCG}
                       />
                    </ItemForm>
        </GroupItem>

        <ItemForm>
         <b>{t("erpCustomerInfo")}</b>
         <p>{data.erpCustomerCode}</p>
         <p>{data.erpCustomerName1}</p>
         <p>{data.erpCustomerAddr1}</p>
         <p>{data.erpCustomerPostCode} {data.erpCustomerCity}</p>
        </ItemForm>

        <ItemForm>
         <b>{t("erpShippingInfo")}</b>
         <p>{data.erpShippingAdrCode}</p>
         <p>{data.erpShippingAdrName1}</p>
         <p>{data.erpShippingAdrAddr1}</p>
         <p>{data.erpShippingAdrPostCode} {data.erpShippingAdrCity}</p>
        </ItemForm>


      </GroupItem>


    </From>
  );
};
