import React, { useState, useEffect } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import classNames from 'classnames';
import { Button,  } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import { Item as ToolbarItem } from 'devextreme-react/toolbar';
import Form, { Item as FormItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { RouteModel } from '../../../types/crm-contact';
import ValidationGroup from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { DateBox, Lookup, SelectBox, TextBox } from 'devextreme-react';
import { salesRoutesUpdateRequest, salesUserRequest } from '../../../api/requests';
import { newRouteModel } from '../../../shared/constants';


export const RouteInfoUpdate = (
  { mainRefresh, route: initialRoute ,readOnlyTrue, readOnlyFalse, readOnly,changePanelOpened, isNewRecord,isPanelOpened,openNewRow } :
  { mainRefresh: () => void, route: RouteModel, readOnlyTrue: () => void, readOnlyFalse: () => void, readOnly: boolean, changePanelOpened: () => void, isNewRecord: boolean, isPanelOpened: boolean, openNewRow: (id: string) => void }
) => {

  const [route, setRoute] = useState(initialRoute);
  const [salesmanList, setSalesmanList] = useState([]);

    useEffect(() => {
      salesUserRequest().then((response)=>{
        setSalesmanList(response.dataSet);
      })
    }, []);

    useEffect(() => {
      if(isNewRecord){
        setRoute(newRouteModel);
      }
    }, [isPanelOpened]);


  // route prop değiştiğinde state'i güncellemek için useEffect kullanımı
  useEffect(() => {
    setRoute(initialRoute);
    if(initialRoute["isNew"]){
      readOnlyFalse();
    }else{
      readOnlyTrue();
    }
    setSelectedStatus(route.routeStatus)
  }, [route.routeRef]);

  function onCancelClick(){
    if(isNewRecord){
      changePanelOpened();
    }else{
      readOnlyTrue();
    }
  }

  function onSaveClick () {
    salesRoutesUpdateRequest(route).then((response)=>{
      if(response.resCode == 200){
        notify(t("successfull"),"success");
        if(isNewRecord){
          const ref = response.dataSet[0]["routeRef"];
         // changePanelOpened();
          openNewRow(ref);

        }else{
          mainRefresh();
          updateField('routeCode')(response.dataSet[0]["routeCode"]);
          //updateField('routeRef')(response.dataSet[0]["routeRef"]);
          updateField('routeName')(response.dataSet[0]["routeName"]);
          updateField('beginTime')(response.dataSet[0]["beginTime"]);
          updateField('endTime')(response.dataSet[0]["endTime"]);
          readOnlyTrue();
        }
      }else{
        notify(response.resMessage,"error");
      }
    })

  }

  const { t } = useTranslation();

  const updateField = (key) => (value) => {
    setRoute(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const statusOptions= [
    { value: 0, label: t("waiting"), color:'red' },
    { value: 1, label: t("started"), color: 'rgb(198, 185, 52)' },
    { value: 2, label: t("completed"), color: 'green' },
  ];

  const handleChange = (e: any) => {
    setSelectedStatus(e.value);
    route.routeStatus = e.value;
  };

  const itemTemplate = (data) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: data.color,
            marginRight: '8px',
          }}
        ></span>
        {data.label}
      </div>
    );
  };

  function hourFormat(datem){
    if(datem == ""){
      return "";
    }else{
      const date = new Date(datem);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }

  }

  return (
    <>
      <ScrollView className='panel-scroll'>
        <ValidationGroup>
          <div className='data-part border'>
            <Form
              className={classNames({ 'plain-styled-form': true, 'view-mode': readOnly })}
            >
              <GroupItem colCount={2}>
              <ToolbarItem location='before' cssClass="left-aligned">
                <CheckBox
                      value={route.routeActive == 1}
                      text={t("active")}
                      onValueChanged={(e) => {
                        updateField('routeActive')(e.value ? 1 : 0);
                      }}
                      readOnly={readOnly}
                      />
                </ToolbarItem>


                <ToolbarItem location='after'  visible={readOnly} cssClass="right-aligned">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={readOnlyFalse} />
                      <div style={{ marginLeft: '10px' }}>
                        <Button text={t("close")} icon='close' style={{backgroundColor:"gray"}} stylingMode='contained' type='default' onClick={changePanelOpened} />
                      </div>
                  </div>
                </ToolbarItem>

                <ToolbarItem location='after' visible={!readOnly} cssClass="right-aligned">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                        text={t("save")}
                        icon='save'
                        stylingMode='contained'
                        type='default'
                        onClick={onSaveClick}
                      />
                    <div style={{ marginLeft: '10px' }}>
                      <Button
                      text={t("cancel")}
                      icon='close'
                      stylingMode='contained'
                      style={{backgroundColor:"gray"}}
                      type='default'
                      onClick={onCancelClick}
                    />
                    </div>
                  </div>
                </ToolbarItem>


              </GroupItem>

              <GroupItem colCount={4} cssClass='photo-row'>
                <ColCountByScreen xs={2} />

                <GroupItem colCount={1}>

                <FormItem cssClass="accent select-box-always-bordered" >
                  <SelectBox
                    dataSource={statusOptions}
                    value={selectedStatus}
                    defaultValue={0}
                    displayExpr="label"
                    valueExpr="value"
                    label={t("routeStatus")}
                    onValueChanged={handleChange}
                    itemRender={itemTemplate}
                    searchEnabled={false}
                    readOnly={isNewRecord&&!readOnly}

                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("routeCode")}
                      value={route.routeCode}
                      readOnly={true}
                      onValueChange={updateField('routeCode')}
                    />
                  </FormItem>


                </GroupItem>


                <GroupItem colCount={1}>
                <FormItem cssClass='accent'>
                    <TextBox
                      label={t("routeName")}
                      value={route.routeName}
                      readOnly={readOnly}
                      onValueChange={updateField('routeName')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                  <Lookup
                    label={t("erpSlsManCode")}
                    readOnly={readOnly}
                    dataSource={salesmanList}
                    value={route.erpSlsManCode}
                    displayExpr={"erpSlsManCode"}
                    valueExpr={"erpSlsManCode"}
                    onValueChange={updateField('erpSlsManCode')}
                    searchPlaceholder={t("search")}
                    placeholder={t("search")}
                    cancelButtonText={t("cancel")}
                    />
                  </FormItem>

                </GroupItem>


                <GroupItem colCount={1}>
                <FormItem cssClass=''>
                    <DateBox
                      label={t("routeDate")}
                      value={route.routeDate}
                      onValueChanged={(e) => updateField('routeDate')(e.value)}
                      displayFormat="dd-MM-yyyy"
                      readOnly={readOnly}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("comment")}
                      value={route.comment}
                      readOnly={readOnly}
                      onValueChange={updateField('comment')}
                    />
                  </FormItem>



                </GroupItem>

                <GroupItem colCount={1}>
                  <FormItem cssClass='accent '>
                  <TextBox
                      label={t("beginDate")}
                      value={hourFormat(route.beginTime)}
                      readOnly={true}
                      onValueChange={updateField('beginTime')}

                    />

                  </FormItem>

                  <FormItem cssClass='accent '>
                  <TextBox
                      label={t("endDate")}
                      value={hourFormat(route.endTime)}
                      readOnly={true}
                      onValueChange={updateField('endTime')}
                    />

                  </FormItem>

                </GroupItem>

              </GroupItem>
            </Form>
          </div>

          <div style={{padding: "5px"}}> </div>
        </ValidationGroup>
      </ScrollView>
    </>
  );
};
