import React, { useState, useEffect } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import classNames from 'classnames';
import { Button, } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import { Item as ToolbarItem } from 'devextreme-react/toolbar';
import Form, { Item as FormItem, GroupItem, Item } from 'devextreme-react/form';
import {  SalesManModel } from '../../../types/crm-contact';
import ValidationGroup from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';
import {  salesUserUpdateRequest } from '../../../api/requests';
import { useTranslation } from 'react-i18next';
import { Popup, TextBox } from 'devextreme-react';
import { Eye, EyeOff } from 'react-feather';
export const SalesmanEditPopup = (
  { mainRefresh, rowData , isPanelOpened,changePanelOpened } :
  { mainRefresh: () => void, rowData:SalesManModel, isPanelOpened,changePanelOpened }
) => {

  const [salesman, setSalesman] = useState(rowData);


  // campaign prop değiştiğinde state'i güncellemek için useEffect kullanımı
  useEffect(() => {
    setSalesman(rowData);
  }, [rowData]);



  function onSaveClick () {
    console.log(salesman);
    try {
        salesUserUpdateRequest(salesman).then(function(res){
            if(res.resCode == 200){
              notify(t("updateSuccessfull"), "success", 1500);
              mainRefresh();
              changePanelOpened();
            }else{
              notify(res.resMessage,"error",3000);
            }
          });

        } catch (error) {
          console.error('API hatası:', error);
        }
  }

  const { t } = useTranslation();

  const updateField = (key) => (value) => {
    setSalesman(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <>
    <Popup
                key={rowData.salesManRef}
                visible={isPanelOpened}
                hideOnOutsideClick={true}
                title={rowData?.salesManName}
                showCloseButton={true}
                defaultWidth={"70%"}
                defaultHeight={"auto"}
                resizeEnabled
                restorePosition
                onHiding={changePanelOpened}
                contentRender={() => (
      <ScrollView className='panel-scroll' >
        <ValidationGroup>
          <div className='data-part border'>
            <Form
              className={classNames({ 'plain-styled-form': true})}
            >
              <GroupItem colCount={2}>
              <ToolbarItem location='before' cssClass="left-aligned">

                <CheckBox
                      value={salesman.salesManActive == 1}
                      text={t("active")}
                      onValueChanged={(e) => {
                        updateField('salesManActive')(e.value ? 1 : 0);
                      }}
                      />

                </ToolbarItem>

                <ToolbarItem location='after' cssClass="right-aligned">
                <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
                </ToolbarItem>

              </GroupItem>

              <GroupItem  colCount={3} cssClass='photo-row'>
                      <GroupItem>
                      <FormItem  cssClass='accent'>
                    <TextBox
                      label={t("salesManName")}
                      value={salesman.salesManName}
                      onValueChange={updateField('salesManName')}
                    />
                  </FormItem>

                  <FormItem visible={false} cssClass='accent'>
                    <TextBox
                      label={t("erpSlsManName1")}
                      value={salesman.erpSlsManName1}
                      onValueChange={updateField('erpSlsManName1')}
                    />
                  </FormItem>



                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("erpSlsManCode")}
                      value={salesman.erpSlsManCode}
                      onValueChange={updateField('erpSlsManCode')}
                    />
                  </FormItem>

                    <FormItem cssClass='accent'>
                    <TextBox
                      label={t("erpFirmNr")}
                      value={salesman.erpFirmNr}
                      onValueChange={updateField('erpFirmNr')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("erpCashBoxCode")}
                      value={salesman.erpCashBoxCode}
                      onValueChange={updateField('erpCashBoxCode')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("salesManEMail")}
                      value={salesman.salesManEMail}
                      onValueChange={updateField('salesManEMail')}
                      inputAttr={{ autocomplete: 'off' }}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <div style={{ position: 'relative' }}>
                        <TextBox
                        label={t("salesManPassword")}
                        mode={isPasswordVisible ? 'text' : 'password'}
                        value={salesman.salesManPassword}
                        onValueChange={updateField('salesManPassword')}
                        inputAttr={{ autocomplete: 'new-password' }}
                        />
                        <div
                        onClick={togglePasswordVisibility}
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer'
                        }}
                        >
                        {isPasswordVisible ? <EyeOff size={"18px"} /> : <Eye size={"18px"} />}
                        </div>
                    </div>
                    </FormItem>
                      </GroupItem>



                      <GroupItem>
                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("erpBranch")}
                      value={salesman.erpBranch}
                      onValueChange={updateField('erpBranch')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("erpDiscLimit")}
                      value={salesman.erpDiscLimit}
                      onValueChange={updateField('erpDiscLimit')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("erpWHCentral")}
                      value={salesman.erpWHCentral}
                      onValueChange={updateField('erpWHCentral')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("erpWHReturn")}
                      value={salesman.erpWHReturn}
                      onValueChange={updateField('erpWHReturn')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("erpWHScrap")}
                      value={salesman.erpWHScrap}
                      onValueChange={updateField('erpWHScrap')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("comment")}
                      value={salesman.comment}
                      onValueChange={updateField('comment')}
                    />
                  </FormItem>
                      </GroupItem>


                        <GroupItem>
                        <Item>
                    <CheckBox
                    value={salesman.erpSalesOrderStatus == 1}
                    text={t("erpSalesOrderStatus")}
                    onValueChanged={(e) => {
                        updateField('erpSalesOrderStatus')(e.value ? 1 : 0);
                    }}
                    />
                </Item>
                <Item>
                    <CheckBox
                    value={salesman.rightSalesOrder == 1}
                    text={t("rightSalesOrder")}
                    onValueChanged={(e) => {
                        updateField('rightSalesOrder')(e.value ? 1 : 0);
                    }}
                    />
                </Item>
                <Item>
                    <CheckBox
                    value={salesman.rightSalesDispatch == 1}
                    text={t("rightSalesDispatch")}
                    onValueChanged={(e) => {
                        updateField('rightSalesDispatch')(e.value ? 1 : 0);
                    }}
                    />
                </Item>
                <Item>
                    <CheckBox
                    value={salesman.rightSalesDispatchRt == 1}
                    text={t("rightSalesDispatchRt")}
                    onValueChanged={(e) => {
                        updateField('rightSalesDispatchRt')(e.value ? 1 : 0);
                    }}
                    />
                </Item>
                <Item>
                    <CheckBox
                    value={salesman.rightSalesInvoice == 1}
                    text={t("rightSalesInvoice")}
                    onValueChanged={(e) => {
                        updateField('rightSalesInvoice')(e.value ? 1 : 0);
                    }}
                    />
                </Item>
                <Item>
                    <CheckBox
                    value={salesman.rightSalesInvoiceRt == 1}
                    text={t("rightSalesInvoiceRt")}
                    onValueChanged={(e) => {
                        updateField('rightSalesInvoiceRt')(e.value ? 1 : 0);
                    }}
                    />
                </Item>

                        <Item>
                    <CheckBox
                    value={salesman.rightPaymentCash == 1}
                    text={t("rightPaymentCash")}
                    onValueChanged={(e) => {
                        updateField('rightPaymentCash')(e.value ? 1 : 0);
                    }}
                    />
                </Item>
                <Item>
                    <CheckBox
                    value={salesman.rightGetPdf == 1}
                    text={t("rightGetPdf")}
                    onValueChanged={(e) => {
                        updateField('rightGetPdf')(e.value ? 1 : 0);
                    }}
                    />
                </Item>
                <Item>
                    <CheckBox
                    value={salesman.rightGetAllProducts == 1}
                    text={t("rightGetAllProducts")}
                    onValueChanged={(e) => {
                        updateField('rightGetAllProducts')(e.value ? 1 : 0);
                    }}
                    />
                </Item>
                <Item>
                    <CheckBox
                    value={salesman.routeActive == 1}
                    text={t("routeActive")}
                    onValueChanged={(e) => {
                        updateField('routeActive')(e.value ? 1 : 0);
                    }}
                    />
                </Item>
                <Item>
                    <CheckBox
                    value={salesman.routeChangeDataAfterSave == 1}
                    text={t("routeChangeDataAfterSave")}
                    onValueChanged={(e) => {
                        updateField('routeChangeDataAfterSave')(e.value ? 1 : 0);
                    }}
                    />
                </Item>
                <Item>
                    <CheckBox
                    value={salesman.routeSendDataAfterSave == 1}
                    text={t("routeSendDataAfterSave")}
                    onValueChanged={(e) => {
                        updateField('routeSendDataAfterSave')(e.value ? 1 : 0);
                    }}
                    />
                </Item>
                        </GroupItem>

               </GroupItem>
               </Form>
          </div>

          <div style={{padding: "5px"}}> </div>
        </ValidationGroup>
      </ScrollView>)} />
    </>
  );
};
