import {  Popup } from "devextreme-react";
import { RouteModel } from "../../../types/crm-contact";
import { useCallback, useEffect, useState } from "react";
import { RouteInfoUpdate } from "./routeInfoUpdate";
import { useTranslation } from "react-i18next";
import { RouteLinesList } from "./routeLinesList";

export const RoutesPopup = (
    { mainRefresh, rowData , isPanelOpened,changePanelOpened,isNewRecord,openNewRow } :
    { mainRefresh: () => void, rowData:RouteModel, isPanelOpened,changePanelOpened,isNewRecord,openNewRow }
  ) =>{
    const [readOnly, setReadOnly] = useState(true);

    useEffect(()=>{
        if(isPanelOpened){
            setExpandTable(false)
            if(isNewRecord){
                setReadOnly(false)
            }else{
                setReadOnly(true)
            }
        }
    },[isPanelOpened]);

    const readOnlyTrue = useCallback(() => {
        setReadOnly(true);
    }, [readOnly]);

    const readOnlyFalse = useCallback(() => {
        setReadOnly(false);
    }, [readOnly]);

    const {t} = useTranslation();

    const[expandTable, setExpandTable] = useState(false);

    return (
        <Popup
                    key={rowData.routeRef}
                    visible={isPanelOpened}
                    hideOnOutsideClick={true}
                    title={rowData?.routeName}
                    showCloseButton={true}
                    width={"90%"}
                    height={"90%"}
                    onHiding={changePanelOpened}
                    contentRender={() => (
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {!expandTable&&(
                        <div style={{position: 'sticky', top: 0, zIndex: 1, borderBottom: '1px solid #ccc',paddingBottom: '10px'}}>
                        <RouteInfoUpdate openNewRow={openNewRow} isPanelOpened={isPanelOpened} changePanelOpened={changePanelOpened} isNewRecord={isNewRecord} readOnly={readOnly} readOnlyTrue={readOnlyTrue} readOnlyFalse={readOnlyFalse} mainRefresh={mainRefresh} route={rowData} ></RouteInfoUpdate>
                        </div>
                    )}
                    <div style={{ flex: "auto", overflowY: 'auto' }}>
                    <RouteLinesList expandTable={expandTable} setExpandTable={setExpandTable} route={rowData} readOnly={readOnly}></RouteLinesList>
                    </div>
                </div>

                    )}
                        />);

}