import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import './b2b-customer-groups.scss';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef
} from 'devextreme-react/data-grid';

import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';

import { ContactStatus as ContactStatusType, CustomerModel } from '../../types/crm-contact';

import { ContactStatus } from '../../components';

import { CONTACT_STATUS_LIST, newContact } from '../../shared/constants';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { customerGroupsRequest} from '../../api/requests';
import { useTranslation } from 'react-i18next';
import { ContactPanel } from '../../components/library/customer-groups/ContactPanel';
import { appInfo } from '../../app-info';



type FilterContactStatus = ContactStatusType | 'Active';


const cellNameRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
  <div className='name-template'>
    <div>{cell.data.customerName}</div>
    <div className='position'>{cell.data.position}</div>
  </div>
);

const editCellStatusRender = () => (
  <SelectBox className='cell-info' dataSource={CONTACT_STATUS_LIST} itemRender={ContactStatus} fieldRender={fieldRender} />
);

const fieldRender = (text: string) => (
  <>
    <ContactStatus text={text} />
    <TextBox readOnly />
  </>
);

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Customers.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Customers.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto' };


export const B2BCustomerGroups = () => {
  const { t } = useTranslation();
  const filterStatusList = [t("all"), t("active"), t("passive")];
  const [gridDataSource, setGridDataSource] = useState<DataSource<CustomerModel[], string>>();
  const [isPanelOpened, setPanelOpened] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const gridRef = useRef<DataGridRef>(null);
  const [rowCount, setRowCount] = useState(0);

  const [rowData, setRowData] = useState();

  function mainRefresh(){

    customerGroupsRequest().then((res)=>{
        setGridDataSource(new DataSource({
            key: 'customerGroupRef',
            load: () => {
              return res.data.dataSet; // API'den gelen veriyi yükle
            },
          }));
      gridRef.current?.instance().endCustomLoading();
    })

  }


  useEffect(() => {

    customerGroupsRequest().then((e)=>{
        setGridDataSource(new DataSource({
            key: 'customerGroupRef',
            load: () => {
              return e.data.dataSet; // API'den gelen veriyi yükle
            },
          }));
    setTimeout(() => {
      if(customerActive == t("passive")){
        gridRef.current?.instance().filter(['customerActive', '=', "0"]);
      }else if(customerActive == t("active")){
        gridRef.current?.instance().filter(['customerActive', '=', "1"]);
      }else{
        gridRef.current?.instance().clearFilter()
      }
      setTimeout(()=>{
     //   gridRef.current?.instance().navigateToRow(customerListSelectFromSession());
      },100)

    }, 100);
  })



    setTimeout(() => {
      if(customerActive == t("passive")){
        gridRef.current?.instance().filter(['customerActive', '=', "0"]);
      }else if(customerActive == t("active")){
        gridRef.current?.instance().filter(['customerActive', '=', "1"]);
      }else{
        gridRef.current?.instance().clearFilter()
      }
      setTimeout(()=>{
       // gridRef.current?.instance().navigateToRow(customerListSelectFromSession());
      },100)

    }, 100);


  }, []);


  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
  }, [isPanelOpened]);

  const changePanelPinned = useCallback(() => {
    gridRef.current?.instance().updateDimensions();
  }, []);

  const onAddContactClick = useCallback(() => {
    setPopupVisible(true);
  }, []);

  const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    setRowData(data);
    setPanelOpened(true);

  }, []);

  const [customerActive, setCustomerActive] = useState(t("active"));

  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
    const { item: customerActive }: { item: FilterContactStatus } = e;

    if (customerActive === t("all")) {
      gridRef.current?.instance().clearFilter();
    } else {
      gridRef.current?.instance().filter(['customerActive', '=', customerActive==t("active")?"1":"0"]);
    }

    setCustomerActive(customerActive);

  }, []);

  const refresh = useCallback(() => {
    mainRefresh();
    gridRef.current?.instance().beginCustomLoading(t("loading"))

  }, []);


  const handleContentReady = (e) => {
    setRowCount(e.component.totalCount());
  };

  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          height='100%'
          focusedRowEnabled
          dataSource={gridDataSource}
          noDataText={t("noData")}
          onRowClick={onRowClick}
          onExporting={onExporting}
          onContentReady={handleContentReady}
          allowColumnReordering
          showBorders
          ref={gridRef}>

          <LoadPanel showPane={false}  />
          <SearchPanel visible placeholder={t("customerSearch")}  />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />

          <Toolbar>

            <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={filterStatusList}
                stylingMode='text'
                text={customerActive}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
            </Item>

            <Item name='searchPanel' location='before' />

            <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text='Refresh'
                stylingMode='text'
                onClick={refresh}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before'/>

            <Item location='center' locateInMenu='auto'>
                {rowCount} {t("records")}
            </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                text='Yeni'
                type='default'
                stylingMode='contained'
                visible={true}
                onClick={onAddContactClick}
              />
            </Item>
          </Toolbar>
          <Column
            dataField='customerActive'
            caption={t("status")}
            hidingPriority={18}
            width={100}
            cellRender={ContactStatus}
          />

            <Column
            dataField='customerGroupName'
            caption="Customer Group Name"
            hidingPriority={19}
          />
          <Column
            dataField='erpSalesManCode'
            caption="ERPSalesManCode"
            hidingPriority={17}
          />
          <Column
            dataField='erpWarehouse'
            caption="ERPWarehouse"
            hidingPriority={16}
          />



          <Column
            dataField='customerGroupCode'
            caption="Customer Group Code"
            hidingPriority={15}
          />

          <Column
            dataField='erpBranch'
            caption="ERP Branch"
            hidingPriority={14}
          />

          <Column
            dataField='erpFirmNr'
            caption="ERP Firm Nr"
            hidingPriority={13}
          />

          <Column
            dataField='productGroupRef'
            caption="Product Group Ref"
            hidingPriority={12}
          />

          <Column
            dataField='comment'
            caption="Comment"
            hidingPriority={11}
          />


        </DataGrid>
        <ContactPanel mainRefresh={mainRefresh}  rowData={rowData} isOpened={isPanelOpened} changePanelOpened={changePanelOpened} changePanelPinned={changePanelPinned} />

      </div>
    </div>
  );
};
