// company kısmına yazılan değere göre fonksiyonlar değişebilir ör: "akar"

var company = "";
var appVersion ="1.0.10";

export const appInfo = () => {

  if(company=="akar"){
      return {
        title: 'mobiOffice',
        version: appVersion,
        url:  'https://api.fbsshop.de',
        imageURL: "https://akar.aysales.de",
        imageServerURL: 'https://pay.mobiserver.de:4244',
        menu:"akar",
        exportFormats : ['xlsx', 'pdf']
      };
    }

  else{
      return {
        title: 'mobiOffice',
        version: appVersion,
        url:  'https://api.fbsshop.de',
        imageURL: "https://image.aysales.de",
        imageServerURL: 'https://pay.mobiserver.de:4243',
        menu:"",
        exportFormats : ['xlsx', 'pdf']
      };
    }
}


