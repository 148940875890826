import React, { useEffect, useState } from 'react';

import TreeList, {
  Column, ColumnChooser, HeaderFilter, SearchPanel, Selection, Lookup,
} from 'devextreme-react/tree-list';
import { testImageRequest } from '../../api/requests';




export const ImageTest= () => {
    const [data,setData] = useState([
        {
            "id": 1,
            "itemActive": 0,
            "itemCode": "00000-00",
"parentId":0,
            "itemName": "EURO PAL 80x120",
            "itemCategory": "999.Diger",
            "itemSubCategory": "",
            "producerCode": "",
            "speCode1": "",
            "speCode2": "",
            "speCode3": "",
            "speCode4": "",
            "speCode5": ""
        },
        {
            "id": 2,
            "itemActive": 1,
            "itemCode": "00044-03",
            "itemName": "SUSAMLI BISKÜVI 70g",
"parentId":1,
            "itemCategory": "01.Bisküvi",
            "itemSubCategory": "Ülker",
            "producerCode": "",
            "speCode1": "",
            "speCode2": "",
            "speCode3": "",
            "speCode4": "",
            "speCode5": ""
        },
        {
            "id": 3,
            "itemActive": 1,
            "itemCode": "00044-04",
            "itemName": "SUSAMLI BISKÜVI 4*70g",
            "itemCategory": "01.Bisküvi",
            "itemSubCategory": "Ülker",
            "producerCode": "",
"parentId":1,
            "speCode1": "",
            "speCode2": "",
            "speCode3": "",
            "speCode4": "",
            "speCode5": ""
        },
        {
            "id": 4,
            "itemActive": 1,
            "itemCode": "00055-04",
            "itemName": "COKOKREM KASE 500g",
            "itemCategory": "09.Cikolata Kremasi",
            "itemSubCategory": "Ülker",
            "producerCode": "",
            "speCode1": "",
"parentId":2,
            "speCode2": "",
            "speCode3": "",
            "speCode4": "",
            "speCode5": ""
        },
        {
            "id": 5,
            "itemActive": 1,
            "itemCode": "00072-00",
            "itemName": "PROBIS SANDVIC 10LU 280G",
            "itemCategory": "01.Bisküvi",
            "itemSubCategory": "Ülker",
            "producerCode": "",
"parentId":0,
            "speCode1": "4.504.K1",
            "speCode2": "4.504.K1",
            "speCode3": "4.504.K1",
            "speCode4": "4.504.K1",
            "speCode5": "4.504.K1"
        }]);


useEffect(()=>{
    testImageRequest().then((response)=>{
        setData(JSON.parse(JSON.stringify(response.dataSet)));

    })
},[])

  return (
    <TreeList
      dataSource={data}
      showBorders={true}
      columnAutoWidth={true}
      wordWrapEnabled={true}
      keyExpr="itemSubCategoryName"
      parentIdExpr="itemCategoryName"
      id='asd'
    >
      <SearchPanel visible={true} width={250} />
      <HeaderFilter visible={true} />
      <Selection mode="multiple" />
      <ColumnChooser enabled={true} />
    <Column dataField='itemCategoryName'></Column>

    </TreeList>
  );
}

