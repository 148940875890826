import React, { useState, useEffect } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import classNames from 'classnames';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import { Item as ToolbarItem } from 'devextreme-react/toolbar';
import Form, { Item as FormItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { SegmentModel } from '../../../types/crm-contact';
import ValidationGroup from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { SelectBox, TextBox } from 'devextreme-react';
import { generalSegmentationPostRequest } from '../../../api/requests';
import { newSegmentModel } from '../../../shared/constants';
import { customerSelectionTypes, productSelectionTypes } from '../../../shared/select-boxes';

export const SegmentInfoUpdate = (
  { mainRefresh, segment: initialSegment , setSegmentProductListRefresh,readOnlyTrue, readOnlyFalse, readOnly, isNewRecord,changePanelOpened,isPanelOpened,openNewRow } :
  { mainRefresh: () => void, segment: SegmentModel, setSegmentProductListRefresh,readOnlyTrue,readOnlyFalse,readOnly,isNewRecord,changePanelOpened,isPanelOpened,openNewRow }
) => {

  const [segment, setSegment] = useState(initialSegment);

  useEffect(() => {
    if(isNewRecord){
      setSegment(newSegmentModel);
    }
    console.log(segment)
  }, [isPanelOpened]);


  function onCancelClick(){
    if(isNewRecord){
      changePanelOpened();
    }else{
      readOnlyTrue();
    }
  }

  function onSaveClick () {

    generalSegmentationPostRequest(segment).then((res) => {
      console.log(res);
      if (res["resCode"] == 200) {
        notify(t("successfull"), "success");

        if(isNewRecord){
          changePanelOpened();
          openNewRow(res.dataSet[0]["segmentRef"])
        }else{
          mainRefresh();
          readOnlyTrue();
        }

      } else {
        notify(res["resMessage"], "error");
      }
    });
  }

  const { t } = useTranslation();

  const updateField = (key) => (value) => {
    setSegment(prev => ({
      ...prev,
      [key]: value
    }));
  };


  return (
    <>
      <ScrollView className='panel-scroll'>
        <ValidationGroup>
          <div className='data-part border'>
            <Form
              className={classNames({ 'plain-styled-form': true, 'view-mode': readOnly })}
            >
              <GroupItem colCount={2}>
              <ToolbarItem location='before' cssClass="left-aligned">

                <CheckBox
                      value={segment.segmentStatus == 1}
                      text={t("active")}
                      onValueChanged={(e) => {
                        updateField('segmentStatus')(e.value ? 1 : 0);
                      }}
                      readOnly={readOnly}
                      />

                </ToolbarItem>

                <ToolbarItem location='after'  visible={readOnly} cssClass="right-aligned">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={readOnlyFalse} />
                      <div style={{ marginLeft: '10px' }}>
                        <Button text={t("close")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={changePanelOpened} />
                      </div>
                  </div>
                </ToolbarItem>

                <ToolbarItem location='after' visible={!readOnly} cssClass="right-aligned">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        text={t("save")}
                        icon='save'
                        stylingMode='contained'
                        type='default'
                        onClick={onSaveClick}
                      />
                    <div style={{ marginLeft: '10px' }}>
                      <Button
                      text={t("cancel")}
                      icon='close'
                      stylingMode='contained'
                      style={{backgroundColor:"gray"}}
                      type='default'
                      onClick={onCancelClick}
                    />
                    </div>
                  </div>
                </ToolbarItem>

              </GroupItem>

              <GroupItem colCount={3} cssClass='photo-row'>
                <ColCountByScreen xs={2} />

                <GroupItem colCount={1}>
                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("segmentCode")}
                      value={segment.segmentCode}
                      readOnly={readOnly}
                      onValueChange={updateField('segmentCode')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("segmentName")}
                      value={segment.segmentName}
                      readOnly={readOnly}
                      onValueChange={updateField('segmentName')}
                    />
                  </FormItem>
                </GroupItem>


                <GroupItem colCount={1}>
                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("specCode1")}
                      value={segment.speCode1}
                      readOnly={readOnly}
                      onValueChange={updateField('speCode1')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("specCode2")}
                      value={segment.speCode2}
                      readOnly={readOnly}
                      onValueChange={updateField('speCode2')}
                    />
                  </FormItem>
                </GroupItem>

                <GroupItem colCount={1}>
                  <FormItem cssClass='accent select-box-always-bordered'>
                    <SelectBox
                     value={segment.productSelectionType}
                    dataSource={productSelectionTypes(t)}
                    onValueChange={updateField('productSelectionType')}
                    label={t("productSelectType")}
                    displayExpr="title"
                     valueExpr="productSelectionType" ></SelectBox>
                  </FormItem>

                  <FormItem cssClass='accent select-box-always-bordered'>
                    <SelectBox
                     value={segment.customerSelectionType}
                    dataSource={customerSelectionTypes(t)}
                    onValueChange={updateField('customerSelectionType')}
                    label={t("customerSelectionType")}
                    displayExpr="title"
                     valueExpr="customerSelectionType" ></SelectBox>
                  </FormItem>

                  <FormItem visible={false} cssClass='accent'>
                    <TextBox
                      label={t("comment")}
                      value={segment.comment}
                      readOnly={readOnly}
                      onValueChange={updateField('comment')}
                    />
                  </FormItem>
                </GroupItem>


              </GroupItem>
            </Form>
          </div>

          <div style={{padding: "5px"}}> </div>
        </ValidationGroup>
      </ScrollView>
    </>
  );
};
